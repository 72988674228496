<template>
  <v-app>
    <default-bar v-if="isLogin()" />

    <default-drawer v-if="isLogin()" />

    <default-view />

    <default-footer v-if="isLogin()" />

    <default-settings v-if="isLogin()" />
  </v-app>
</template>

<script>
  import { sync } from 'vuex-pathify'
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultFooter: () => import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
      DefaultSettings: () => import(
        /* webpackChunkName: "default-settings" */
        './Settings'
      ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },
    computed: {
    ...sync('userProfile', [
      'dataProfile',
    ]),
  },
    async created() {
      // call API fetchDataUserProfile from Store
      const tokenLogin = this.getTokenLogin()
      if (!tokenLogin) {
        return
      }
      const resultUserLogin = await this.$store.dispatch('userProfile/fetchDataUserProfile', this.getTokenLogin())
      if (!resultUserLogin.status) {
        localStorage.clear()
        location.reload()
        return
      }
      const dataProfile = resultUserLogin?.data || {}
      this.dataProfile = {
        email: dataProfile?.email || '',
        fullName: dataProfile?.fullName || '',
        role: dataProfile?.role || '',
        dataGroups: dataProfile?.dataGroups || [],
      }
    },
  }
</script>
